import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import dataV from '@jiaminghi/data-view';
Vue.use(dataV);
// 按需引入vue-awesome图标
import Icon from 'vue-awesome/components/Icon';
import 'vue-awesome/icons/chart-bar.js';
import 'vue-awesome/icons/chart-area.js';
import 'vue-awesome/icons/chart-pie.js';
import 'vue-awesome/icons/chart-line.js';
import 'vue-awesome/icons/align-left.js';
import moment from "moment"
Vue.prototype.$moment = moment;
// 全局注册图标
Vue.component('icon', Icon);

// 适配flex
import '@/common/flexible.js';
import less from 'less'
Vue.use(less)

//引入echart
import echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;
import ElementUI from 'element-ui';

// a.2 引入element-ui css 样式

import 'element-ui/lib/theme-chalk/index.css';
// 引入全局css

// a.3 在Vue中注册使用 ElementUI 模块

import VueParticles from 'vue-particles'
Vue.prototype.$EventBus = new Vue()
Vue.use(VueParticles)
Vue.use(ElementUI);
import {
    Message
} from 'element-ui'

// 挂载到$message上
Vue.prototype.$message = Message
import './assets/scss/style.scss';
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');