import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const routes = [{
        path: "/login",
        name: "login",
        component: () => import("../views/demo.vue"),
    },
    {
        path: "*", // 重定向页面地址
        redirect: "/index",
    },
    {
        path: "/index",
        name: "index",
        meta: {
            title: "江溪街道污染物在线监测系统",
        },
        component: () => import("../views/index.vue"),
        children: [],
    },
    {
        path: "/backmanger",
        name: "index",
        meta: {
            title: "江溪街道污染物在线监测系统",
        },
        component: () => import("../views/backmanger/index.vue"),
        children: [{
                path: "earlywaringmanage",
                name: "预警配置",
                meta: {
                    title: "预警配置",
                },
                component: () => import("../views/earlyWaringManage/index.vue"),
            },
            {
                path: "historywaring",
                name: "历史预警",
                meta: {
                    title: "历史预警",
                },
                component: () => import("../views/historywaring/index.vue"),
            },
            {
                path: "realtimeauditing",
                name: "实时数据审核",
                meta: {
                    title: "历史预警",
                },
                component: () => import("../views/realtimeauditing/index.vue"),
            },
            {
                path: "realtimedatacavans",
                name: "实时数据图表",
                meta: {
                    title: "历史预警",
                },
                component: () => import("../views/realtimedatacavans/index.vue"),
            },
            {
                path: "alldate",
                name: "年月日数据",
                meta: {
                    title: "年月日数据",
                },
                component: () => import("../views/alldate/index.vue"),
            },
        ],
    },
    {
        path: "/daily",
        name: "index",
        component: () => import("../views/backmanger/index.vue"),
        children: [{
                path: "searchdata",
                name: "数据查询",
                meta: {
                    title: "数据查询",
                },
                component: () => import("../views/daily/searchdata/index.vue"),
            },
            {
                path: "dailyreport",
                name: "历史预警",
                meta: {
                    title: "历史预警",
                },
                component: () => import("../views/daily/dailyreport/index.vue"),
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    routes,
});
router.beforeEach((to, from, next) => {
    document.title = to.name + " | 江溪街道空气检测平台";
    next();
    const tokenStr = localStorage.getItem("bigdatatoken");
    if (to.path === "/login") return next();
    // 获取token

    // 如果token为空
    if (!tokenStr) {
        return next("/login");
    }
});

export default router;